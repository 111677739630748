@import '../../../../scss/theme-bootstrap';
///
/// collapsible-block
/// A content block that becomes click-expandable with a title
///
.collapsible-block {
  border-bottom: $color-black solid 1px;
  padding: 1em;
  position: relative;
}

.collapsible-block__title {
  @include font--subheading-alt;
  font-size: 24px;
  position: relative;
  cursor: pointer;
  .cs-page__content & {
    @include h3;
    color: $color-black;
  }
}

.collapsible-block__icon {
  @include icon('caret--right', after);
  @include single-transition($default-transition-property, $default-transition-duration, $default-transition-function);
  font-size: 18px;
  position: absolute;
  right: 0;
  top: 0.15em;
  .cs-page__content & {
    font-size: 14px;
    top: 0;
  }
  .collapsible-block--alt & {
    @include icon('caret--down', after);
  }
  .collapsible-block--mobile-collapsed & {
    @include icon('caret--down', after, $mq: true);
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
  .collapsible-block--mobile-collapsed.collapsible-block--alt & {
    @include icon('caret--right', after, $mq: true);
  }
  .collapsible-block--mobile-collapsed & {
  }
}

.collapsible-block__content {
  @include single-transition($default-transition-property, $default-transition-duration, $default-transition-function);
  height: 0;
  overflow: hidden;
  .collapsible-block--alt & {
    height: auto;
  }
  .collapsible-block--mobile-collapsed & {
    @include breakpoint($landscape-down) {
      height: auto;
    }
  }
  .collapsible-block--mobile-collapsed.collapsible-block--alt & {
    @include breakpoint($landscape-down) {
      height: 0;
      overflow: hidden;
    }
  }
}
