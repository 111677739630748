// This file is generated by `gulp icons`, do not modify

$icons: (
  arrow--down: "\EA01",
  arrow--up: "\EA02",
  arrow-long-l: "\EA03",
  arrow-long-r: "\EA04",
  auto-replenish: "\EA05",
  bag: "\EA06",
  camera-thicker: "\EA07",
  camera: "\EA08",
  caret--down: "\EA09",
  caret--left: "\EA0A",
  caret--right: "\EA0B",
  caret--up: "\EA0C",
  caret-thin--left: "\EA0D",
  caret-thin--right: "\EA0E",
  caret-thin-white--left: "\EA0F",
  caret-thin-white--right: "\EA10",
  caret-with-bg--left: "\EA11",
  caret-with-bg--right: "\EA12",
  caret-with-bg-semi-opaque--left: "\EA13",
  caret-with-bg-semi-opaque--right: "\EA14",
  caret-with-bg-semi-opaque-white--left: "\EA15",
  caret-with-bg-semi-opaque-white--right: "\EA16",
  caret-with-bg-white--left: "\EA17",
  caret-with-bg-white--right: "\EA18",
  cart: "\EA19",
  chat: "\EA1A",
  checkbox--checked: "\EA1B",
  checkbox: "\EA1C",
  circle-caret--left: "\EA1D",
  circle-caret--right: "\EA1E",
  circle-filled: "\EA1F",
  circle-next: "\EA20",
  circle-outline: "\EA21",
  circle-prev: "\EA22",
  close-inverted: "\EA23",
  close-x: "\EA24",
  close: "\EA25",
  email: "\EA26",
  facebook: "\EA27",
  hamburger: "\EA28",
  head--outline: "\EA29",
  head--solid: "\EA2A",
  heart--selected: "\EA2B",
  heart: "\EA2C",
  information: "\EA2D",
  instagram: "\EA2E",
  livechat-outline: "\EA2F",
  livechat: "\EA30",
  location--filled: "\EA31",
  location-search: "\EA32",
  location: "\EA33",
  logo: "\EA34",
  map-marker: "\EA35",
  minus--bold: "\EA36",
  minus: "\EA37",
  phone: "\EA38",
  pinterest--circle: "\EA39",
  pinterest: "\EA3A",
  plus--bold: "\EA3B",
  plus: "\EA3C",
  question: "\EA3D",
  radio--checked: "\EA3E",
  radio: "\EA3F",
  search: "\EA40",
  social-fb: "\EA41",
  social-instagram: "\EA42",
  social-pinterest: "\EA43",
  social-tiktok: "\EA44",
  social-twitter: "\EA45",
  social-youtube: "\EA46",
  stars: "\EA47",
  tiktok: "\EA48",
  triangle-down: "\EA49",
  triangle-left: "\EA4A",
  triangle-right: "\EA4B",
  triangle-up: "\EA4C",
  twitter: "\EA4D",
  video-play-button--black: "\EA4E",
  video-play-button--white: "\EA4F",
  video-play: "\EA50",
  vk: "\EA51",
  x-lower: "\EA52",
  x-upper: "\EA53",
  xo: "\EA54",
  youtube--play: "\EA55",
  youtube: "\EA56",
);
